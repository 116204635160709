import React, { useState, useEffect } from 'react';
import {
    Paper,
    Box,
    TextField,
    Grid,
    Button,
    Stack,
    Divider,
    Chip,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableCell,
    TableRow
} from "@mui/material";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import SearchDropDown from "../atoms/SearchDropdown";
import InputFileUpload from '../atoms/InputFileUpload';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { useSelector, useDispatch } from "react-redux";
import {
    uploadAsync,
    calculateAsync
} from "../../redux/asyncThunks";
import {
    clearModelData,
    clearModelResult
} from "../../redux/slices/model.slices";
import _ from "lodash";
import { dataField, category, probField } from '../../utils/constants';
import { buildData, renderHtmlWithJsonData } from "../../utils/helper";
import { PostAdd } from '@mui/icons-material';
import CustomRadioGroup from '../atoms/CustomRadioGroup';
import { key } from 'localforage';

import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export const Dashboard = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState({});
    const [helper, setHelper] = useState({});
    const [showResult, setShowResult] = useState(true);
    const [rpApplicable, setRPApplicable] = useState(true);
    const { fileProcessed, fileUploaded, processedData, calculationResult, calculationInProgress } = useSelector((state) => state.model);
    const { authToken } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState("Select Name");
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        var data = {}
        var error = {}
        var helper = {}
        dataField.map(field => {
            data[field.value] = field.value
            error[field.value] = false
            helper[field.value] = ""
            return {}
        })
        setData(data)
        setError(error)
        setHelper(helper)
        setDefaultValue()
        dispatch(clearModelData())
    }, [])

    useEffect(() => {
        if (!_.isEmpty(calculationResult) && !calculationInProgress) {
            let data = buildData(calculationResult)
            let htmlContent = renderHtmlWithJsonData(data)
            const newWindow = window.open('', '_blank', 'width=794,height=1122');
            if (newWindow && !newWindow.closed) {
                newWindow.document.write(htmlContent);
            }
        }
    }, [calculationResult, calculationInProgress])

    const handleDateChange = (newDate, id) => {
        var age = null;
        setData(prevData => ({
            ...prevData,
            [id]: newDate
        }));

        if (id === "Birthday") {
            if (newDate.isValid() && data["VisitDate"].isValid()) {
                age = data["VisitDate"].diff(newDate, 'd') / (365.2425)
                setData(prevData => ({
                    ...prevData,
                    ["Age"]: age.toFixed(1)
                }));
            }
        }

        if (id === "VisitDate") {
            if (data["Birthday"].isValid() && newDate.isValid()) {
                age = newDate.diff(data["Birthday"], 'd') / (365.2425)
                setData(prevData => ({
                    ...prevData,
                    ["Age"]: age.toFixed(1)
                }));
            }
        }
    }

    const handleChange = (e, id) => {
        const fields = dataField.filter(field => field.value === id);
        let isValid = true;
        var errMsg = ""
        if (fields.length > 0) {
            const { type } = fields[0]
            const regex = /^-?\d*\.?\d*$/;
            if (type !== "string" && e.target.value.length > 0 && e.target.value !== '-') {
                const { min, max } = fields[0]

                if (!regex.test(e.target.value)) {
                    isValid = false
                } else if (min !== null && max !== null && !(min < e.target.value && max > e.target.value)) {
                    isValid = false;
                } else if (min !== null && !(min < e.target.value)) {
                    isValid = false;
                } else if (max !== null && !(max > e.target.value)) {
                    isValid = false;
                }
                if (!isValid) {
                    isValid = false;
                    errMsg = "Accepts only number"
                    if (min !== null && max !== null) {
                        errMsg += " between " + min + " and " + max
                    } else if (min !== null) {
                        errMsg += " greater than " + min
                    } else if (max !== null) {
                        errMsg += " less than " + max
                    }
                }
            }
        }

        if (isValid) {
            setData(prevData => ({
                ...prevData,
                [id]: e.target.value
            }));
            setError(prevError => ({
                ...prevError,
                [id]: false
            }));
            setHelper(prevHelper => ({
                ...prevHelper,
                [id]: ""
            }));
        } else {
            setError(prevError => ({
                ...prevError,
                [id]: true
            }));
            setHelper(prevHelper => ({
                ...prevHelper,
                [id]: errMsg
            }));
        }
    };

    const setDefaultValue = () => {
        var defData = {}
        dataField.map(field => {
            const { value, defVal, type } = field;
            defData[value] = defVal
            if (type === 'date') {
                defData[value] = null
            }
            return {}
        })
        setData(defData);
    }
    const handleClear = e => {
        setSelectedOption("Select Name")
        setSearchText("")
        setDefaultValue()
        dispatch(clearModelResult())
    }


    const handleSubmit = e => {
        let reqData = {}
        let error = false;
        var keyCnt = 0;
        dataField.map(field => {
            const { value, required, type } = field;
            if (type === "number" || type === "decimal") {
                reqData[value] = (data[value] !== null) ? parseFloat(data[value]) : null
            } else if (type === "date") {
                reqData[value] = (data[value] !== null) ? dayjs(data[value]).format("YYYY-MM-DD") : null
            } else if (type === "option") {
                reqData[value] = +data[value]
            }
            else {
                reqData[value] = data[value]
            }

            if (value.includes('eGFR_time_series_')) {
                if (data.hasOwnProperty(value) && data[value] !== null) {
                    keyCnt++;
                }
            }
            if (required && (reqData[value] === null || reqData[value] === '')) {
                error = true;
                setError(prevError => ({
                    ...prevError,
                    [value]: true
                }));
            }
            return {}
        })

        if (!error) {
            if (reqData[`eGFR_time_series_${keyCnt}`] !== reqData['Baseline_eGFR']) {
                reqData[`eGFR_time_series_${keyCnt + 1}`] = reqData['Baseline_eGFR']
            }

            dispatch(clearModelResult())
            dispatch(calculateAsync({ ...reqData, "accessToken": authToken, "Model": "IR", "Year": "1y" }));
            dispatch(calculateAsync({ ...reqData, "accessToken": authToken,"Model": "IR", "Year": "2y" }));
            dispatch(calculateAsync({ ...reqData, "accessToken": authToken,"Model": "IR", "Year": "5y" }));
            if (reqData['Baseline_eGFR'] >= 15) 
            {
                setRPApplicable(true)
                dispatch(calculateAsync({ ...reqData, "accessToken": authToken,"Model": "RP_pilot", "Year": "1y" }));
                dispatch(calculateAsync({ ...reqData, "accessToken": authToken,"Model": "RP_pilot", "Year": "2y" }));
                dispatch(calculateAsync({ ...reqData, "accessToken": authToken,"Model": "RP_pilot", "Year": "5y" }));
            }else{
                setRPApplicable(false)
            }
        }
    };

    const processIncomingData = (incomingData) => {
        var data = {}
        dataField.map(field => {
            const { value, type, format } = field;
            switch (type) {
                case "date":
                    data[value] = dayjs(incomingData[value], format, true)
                    break
                default:
                    data[value] = incomingData[value]
            }
            return {}
        })
        setData(data)
    }

    const handleSelectName = (value) => {
        const filteredData = processedData.filter(o => o.Name === value)
        setDefaultValue()
        setSelectedOption(value)
        processIncomingData(filteredData[0])
    }

    const renderProbField = (field) => {
        var finalValue = "";
        if (!_.isEmpty(calculationResult)) {
            finalValue = calculationResult[0][field]
        }
        return (
            <>
                <Grid item xs={2}>
                    {(
                        <TextField
                            disabled
                            id={field}
                            label={field}
                            value={finalValue}
                        />
                    )}
                </Grid>
            </>);
    }

    const renderByCategory = category => {
        const fieldByCategory = dataField.filter(field => field.category === category.value);
        return (
            <>
                <Divider >
                    <Chip label={category.label} size='medium' />
                </Divider>
                <Box>
                    <Grid container spacing={2}>
                        {fieldByCategory.map(field => renderDataField(field, (12 / category.itemsPerRow)))}
                    </Grid>
                </Box>
            </>
        )
    }

    const renderModelResults = (model, years, label, applicable) => {
        const renderYear = (year, applicable) => {
            var yrLabel = "1 Year"
            var status = "DONE"

            var prob = '-'
            var score = '-'
            var category = '-'

            if (!_.isEmpty(calculationResult)) {
                status = calculationResult[`${model}_Status_${year}`];
                prob = calculationResult[`${model}_prob_${year}(%)`] ? parseFloat(calculationResult[`${model}_prob_${year}(%)`]).toFixed(5) : '-';
                score = calculationResult[`${model}_Score_${year}`] ? calculationResult[`${model}_Score_${year}`] : '-';
                category = calculationResult[`${model}_Category_${year}`] ? calculationResult[`${model}_Category_${year}`] : '-';
            }

            if (year === '2y') {
                yrLabel = "2 Year"
            } else if (year === '5y') {
                yrLabel = "5 Year"
            }
            return (<TableRow>
                <TableCell align="center">
                    {yrLabel}
                </TableCell>
                {applicable && status !== "LOADING" &&
                    <>
                        <TableCell align="center">
                            {prob}
                        </TableCell>
                        <TableCell align="center">
                            {score}
                        </TableCell>
                        <TableCell align="center">
                            {category}
                        </TableCell>
                    </>
                }
                {applicable && status === "LOADING" &&
                    <>
                        <TableCell align="center" colSpan={3}>
                            Calculating
                        </TableCell>
                    </>
                }
                {!applicable &&
                    <>
                        <TableCell align="center" colSpan={3}>
                            NOT APPLICABLE
                        </TableCell>
                    </>
                }
            </TableRow>)
        }
        return (<TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={4}>
                            {label}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="center">
                            Duration
                        </TableCell>
                        <TableCell align="center">
                            Percentage
                        </TableCell>
                        <TableCell align="center">
                            Score
                        </TableCell>
                        <TableCell align="center">
                            Category
                        </TableCell>
                    </TableRow>
                    {years.map(year => renderYear(year, applicable))}
                </TableBody>
            </Table>
        </TableContainer>);
    }

    const renderResults = () => {
        return (
            <Box sx={{ p: "20px", bgcolor: "primary", width: "100%" }}>
                {renderModelResults("IR", ['1y', '2y', '5y'], "Initiation of RRT", true)}
                {renderModelResults("RP_pilot", ['1y', '2y', '5y'], "Rapid Progression", rpApplicable)}
            </Box>
        )
    }
    const renderDataField = (field, xs) => {
        const { value, type, required, label, disabled, defVal } = field;
        switch (type) {
            case "option":
                const optValue = data[value] ? data[value] : 0
                return (
                    <Grid item xs={xs}>
                        <CustomRadioGroup field={field} optionValue={optValue} handleChange={handleChange} />
                    </Grid>);
            case "date":
                return (
                    <Grid item xs={xs}>
                        <DateField
                            required={required}
                            color="secondary"
                            value={(data[value]=="VisitDate" || data[value]=="Birthday")?defVal:data[value]}
                            label={label}
                            onChange={(newValue) => handleDateChange(newValue, value)}
                            format='DD/MM/YYYY'
                        />
                    </Grid>)
            case "string":
            case "decimal":
            default:
                const finalValue = data[value] ? data[value] : ''
                return (
                    <Grid item xs={xs}>
                        {(
                            <TextField
                                color='secondary'
                                disabled={disabled}
                                required={required}
                                label={label}
                                id={value}
                                value={finalValue}
                                onChange={(event) => handleChange(event, value)}
                                error={error[value]}
                                helperText={helper[value]}
                            />
                        )}
                    </Grid>
                );
        }
    };

    const onDrop = (e) => {
        const acceptedFileTypes = ['text/csv'];

        if (e.target.files.length === 1) {
            const file = e.target.files[0];
            if (acceptedFileTypes.includes(file.type)) {
                // File is a CSV
                setSelectedOption("Select Name")
                setSearchText("")
                setDefaultValue()
                dispatch(uploadAsync({'file': file, 'accessToken':authToken}));
            } else {
                alert('Please upload a CSV file.');
            }
        } else {
            alert('Please upload only one file.');
        }
    }

    return (
        <Box sx={{ p: "20px", bgcolor: "secondary.main", height: "100%" }}>
            <Box sx={{ p: "10px", bgcolor: "primary.secondary", height: "100%" }}>
                <Box sx={{ p: "20px", bgcolor: "primary.main", height: "100%" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            {<Button color="secondary" variant="contained" startIcon={<ClearAllIcon />} onClick={() => handleClear()}>CLEAR ALL</Button>}
                        </Grid>
                        <Grid item xs={2}>
                            <InputFileUpload handleOnChange={onDrop} />
                        </Grid>

                        {fileUploaded && fileProcessed &&
                            <>
                                <Grid item xs={2}>
                                    <SearchDropDown
                                        selectedOption={selectedOption}
                                        handleSelectName={handleSelectName}
                                        searchText={searchText}
                                        setSearchText={setSearchText}
                                        allOptions={processedData}
                                    />
                                </Grid>
                            </>
                        }
                        {(true || (fileProcessed && selectedOption !== "Select Name")) &&
                            <>
                                <Grid item xs={2}>
                                    <Button color="secondary" variant="contained" onClick={() => handleSubmit()}>CALCULATE</Button>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Box>
            </Box>
            {(true || (fileProcessed && selectedOption !== "Select Name")) &&
                <>
                    {showResult && <Box sx={{ p: "10px", bgcolor: "primary.secondary", height: "100%" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={7}>
                                <Box sx={{ p: "40px", bgcolor: "primary.main", height: "100%" }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateField']}>
                                            <Stack spacing={2}>
                                                {category.map(category => renderByCategory(category))}
                                            </Stack>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Box sx={{ p: "40px", bgcolor: "primary.main", height: "100%" }}>
                                    <Grid container spacing={2}>
                                        {
                                            renderResults()
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    }
                    {!showResult && <Box sx={{ p: "10px", bgcolor: "primary.secondary", height: "100%" }}>
                        <Box sx={{ p: "40px", bgcolor: "primary.main", height: "100%" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateField']}>
                                    <Stack spacing={2}>
                                        {category.map(category => renderByCategory(category))}
                                    </Stack>
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Box>}
                </>
            }
        </Box>
    );
};
import { Radio, RadioGroup, FormControl, FormControlLabel, FormLabel } from "@mui/material";
import { pink } from '@mui/material/colors';

export default function CustomRadioGroup({ field, optionValue, handleChange }) {
    const { value, label, required, options } = field;

    return (<FormControl variant="standard">
        <FormLabel color='secondary'>{label}</FormLabel>
        <RadioGroup
            required={required}
            row
            color='secondary'
            id={value}
            label={label}
            value={optionValue}
            onChange={(event) => handleChange(event, value)}
        >
            {Object.keys(options).map(key => {
                var optKey = value + "_" + key
                return (
                    <FormControlLabel color='secondary' value={key} control={<Radio
                        key={optKey}
                        sx={{
                            color: 'secondary',
                            '&.Mui-checked': {
                                color: 'secondary.main',
                            },
                        }} />} label={options[key]} />
                )
            })}
        </RadioGroup>
    </FormControl>);
} 
import "./styles.css";
import React, { useMemo } from "react";
import { Select, MenuItem, ListSubheader, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { BootstrapInput } from "../BootstrapInput";
import { isNull } from "lodash";

const containsText = (text, searchText) => {
	return text.toLowerCase().includes(searchText.toLowerCase());
};

export default function SearchDropDown({ selectedOption, handleSelectName, searchText, setSearchText, allOptions }) {
	const displayedOptions = useMemo(() => {
		return allOptions ? allOptions
			.filter((o) => containsText(isNull(o?.Name) ? "Unknown" : `${o?.Name}`, searchText))
			.map((o, i) => (
				<MenuItem key={i} value={o.Name}>
					{isNull(o?.Name) ? "Unknown" : `${o?.Name}`}
				</MenuItem>
			)) : (
			<MenuItem>
				No items found
			</MenuItem>
		)
	}, [searchText, allOptions]);

	const formDropdownLabel = (selectedOption) => {
		if(selectedOption === "Select Name"){
			return "Select Name"
		}
		if(allOptions){
			const filteredOutput = allOptions.filter((data) => data.Name === selectedOption)
			const filteredUserDetail = filteredOutput && filteredOutput[0]
			return isNull(filteredUserDetail?.Name) ? (
				"Unknown"
			) : (
				`${filteredUserDetail?.Name}`
			)
		}
	}

	return (
		<Select
			// Disables auto focus on MenuItems and allows TextField to be in focus
			MenuProps={{
				style: {
					maxHeight: 460,
					autoFocus: false,
				},
			}}
			sx={{ minWidth: "150px"}}
			labelId="search-select-label"
			id="search-select"
			value={selectedOption}
			label="Options"
			onChange={(e) => handleSelectName(e.target.value)}
			onClose={() => setSearchText("")}
			// This prevents rendering empty string in Select's value
			// if search text would exclude currently selected option.
			renderValue={() => formDropdownLabel(selectedOption)}
			input={<BootstrapInput />}
		>
			{/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
			<ListSubheader>
				<TextField
					size="small"
					autoFocus
					placeholder="Type to search..."
					fullWidth
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
					sx={{
						backgroundColor: "#eee",
						borderRadius: "12px"
					}}
					onChange={(e) => setSearchText(e.target.value)}
					onKeyDown={(e) => {
						if (e.key !== "Escape") {
							// Prevents autoselecting item while typing (default Select behaviour)
							e.stopPropagation();
						}
					}}
				/>
			</ListSubheader>
			{displayedOptions}
		</Select>
	);
}

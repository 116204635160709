import { createAsyncThunk } from "@reduxjs/toolkit";
import { ASYNC_ROUTES } from "../reduxConstants/async.constants";
import { calculateService, uploadService } from "../services/model.service";

export const uploadAsync = createAsyncThunk(ASYNC_ROUTES.UPLOAD, async (payload, { rejectWithValue }) => {
	try {
		const response = await uploadService(payload);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const calculateAsync = createAsyncThunk(ASYNC_ROUTES.CALCULATE, async (payload, { rejectWithValue }) => {
	try {
		const response = await calculateService(payload);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

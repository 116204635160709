export const ROUTE_CONSTANTS = {
  BASE: "/",
  DASHBOARD: "/dashboard",
};

export const category = [
  {
      "value": "required",
      "label": "Required Details",
      "itemsPerRow": 4,
  },
  {
      "value": "lifestyle",
      "label": "Lifestyle",
      "itemsPerRow": 4,
  },
  {
      "value": "comorbidity",
      "label": "Comorbidity",
      "itemsPerRow": 4,
  },
  {
      "value": "function",
      "label": "Kidney function",
      "itemsPerRow": 4,
  },
  {
      "value": "nutrition",
      "label": "Kidney Nutrition",
      "itemsPerRow": 4,
  },
  {
      "value": "electrolyte",
      "label": "Kidney Electrolytes",
      "itemsPerRow": 4,
  },
  {
      "value": "egfr",
      "label": "Additional eGFR(s)",
      "itemsPerRow": 4,
  },
]

export const dataField = [
  {
      "value": "Name",
      "label": "Name",
      "category": "required",
      "type": "string",
      "required": true,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "PatientNo",
      "label": "Patient No",
      "category": "required",
      "type": "string",
      "required": true,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Birthday",
      "label": "Date Of Birth",
      "category": "required",
      "type": "date",
      "format": "YYYY-MM-DD",
      "required": true,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "VisitDate",
      "label": "Visit Date",
      "category": "required",
      "type": "date",
      "format": "YYYY-MM-DD",
      "required": true,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Age",
      "label": "Age",
      "category": "required",
      "type": "decimal",
      "required": true,
      "min": null,
      "max": null,
      "disabled": true,
      "defVal": null
  },
  {
      "value": "Baseline_eGFR",
      "label": "eGFR",
      "category": "required",
      "type": "decimal",
      "required": true,
      "min": 0,
      "max": 60,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Sex",
      "label": "Sex",
      "category": "required",
      "type": "option",
      "options": { "1": "Male", "0": "Female" },
      "required": true,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": 1
  },
  {
      "value": "Smoking",
      "label": "Smoking",
      "category": "lifestyle",
      "type": "option",
      "options": { "1": "Yes", "0": "No" },
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": 0
  },
  {
      "value": "Alcohol",
      "label": "Drinking",
      "category": "lifestyle",
      "type": "option",
      "options": { "1": "Yes", "0": "No" },
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": 0
  },
  {
      "value": "DM",
      "label": "Diabetes",
      "category": "comorbidity",
      "type": "option",
      "options": { "1": "Yes", "0": "No" },
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": 0
  },
  {
      "value": "HTN",
      "label": "Hypertension",
      "category": "comorbidity",
      "type": "option",
      "options": { "1": "Yes", "0": "No" },
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": 0
  },
  {
      "value": "SBP",
      "label": "Systolic BP (mmHg)",
      "category": "comorbidity",
      "type": "decimal",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "DBP",
      "label": "Diastolic BP (mmHg)",
      "category": "comorbidity",
      "type": "decimal",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Blood_Albumin",
      "label": "Albumin (g/dL)",
      "category": "nutrition",
      "type": "decimal",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Blood_BUN",
      "label": "Urea Nitrogen (g/dL)",
      "type": "decimal",
      "category": "function",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Blood_Calcium",
      "label": "Calcium (mg/dL)",
      "type": "decimal",
      "category": "electrolyte",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Blood_Glucose_Fasting",
      "label": "Fasting Glucose (mg/dL)",
      "type": "decimal",
      "category": "comorbidity",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Blood_Hb",
      "label": "Hemoglobin (g/dL)",
      "type": "decimal",
      "category": "nutrition",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Blood_K",
      "label": "Potassium (mmol/L)",
      "type": "decimal",
      "category": "electrolyte",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Blood_Na",
      "label": "Sodium (mmol/L)",
      "type": "decimal",
      "category": "electrolyte",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Blood_P",
      "label": "Phosphate (mg/dL)",
      "type": "decimal",
      "category": "electrolyte",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Blood_TCHO",
      "label": "Total Cholestrol (mg/dL)",
      "type": "decimal",
      "category": "nutrition",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Blood_TG",
      "label": "Triglyceride (mg/dL)",
      "type": "decimal",
      "category": "nutrition",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Blood_UA",
      "label": "Uric Acid (mg/dL)",
      "type": "decimal",
      "category": "function",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Urine_Creatinine",
      "label": "Creatinine (mg/dL)",
      "category": "function",
      "type": "decimal",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "Urine_TotalProtein",
      "label": "Protein (mg/dL)",
      "type": "decimal",
      "category": "function",
      "required": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "uACR",
      "label": "uACR (mg/g)",
      "type": "string",
      "category": "function",
      "float": false,
      "min": null,
      "max": null,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "eGFR_time_series_1",
      "label": "eGFR 1",
      "category": "egfr",
      "type": "decimal",
      "required": false,
      "min": 0,
      "max": 60,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "eGFR_time_series_2",
      "label": "eGFR 2",
      "type": "decimal",
      "category": "egfr",
      "required": false,
      "min": 0,
      "max": 60,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "eGFR_time_series_3",
      "label": "eGFR 3",
      "type": "decimal",
      "category": "egfr",
      "required": false,
      "min": 0,
      "max": 60,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "eGFR_time_series_4",
      "label": "eGFR 4",
      "category": "egfr",
      "type": "decimal",
      "required": false,
      "min": 0,
      "max": 60,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "eGFR_time_series_5",
      "label": "eGFR 5",
      "type": "decimal",
      "category": "egfr",
      "required": false,
      "min": 0,
      "max": 60,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "eGFR_time_series_6",
      "label": "eGFR 6",
      "type": "decimal",
      "category": "egfr",
      "required": false,
      "min": 0,
      "max": 60,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "eGFR_time_series_7",
      "label": "eGFR 7",
      "type": "decimal",
      "category": "egfr",
      "required": false,
      "min": 0,
      "max": 60,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "eGFR_time_series_8",
      "label": "eGFR 8",
      "type": "decimal",
      "category": "egfr",
      "required": false,
      "min": 0,
      "max": 60,
      "disabled": false,
      "defVal": null
  },
  {
      "value": "eGFR_time_series_9",
      "label": "eGFR 9",
      "type": "decimal",
      "category": "egfr",
      "required": false,
      "min": 0,
      "max": 60,
      "disabled": false,
      "defVal": null
  }
]

export const probField = [
  'IR_prob_1y(%)',
  'IR_prob_2y(%)',
  'IR_prob_5y(%)',
  'RP_pilot_prob_1y(%)',
  'RP_pilot_prob_2y(%)',
  'RP_pilot_prob_5y(%)'
]
import { MainLayout } from "../layouts";
import { Dashboard } from "../UI/template";
import { ROUTE_CONSTANTS } from "../utils/constants";

export const APP_ROUTES = [
	{
		element: <MainLayout />,
		children: [
			{
				path: ROUTE_CONSTANTS.BASE,
				element: <Dashboard />,
			},
		],
	},
];

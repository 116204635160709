import { createSlice } from "@reduxjs/toolkit";
import { THUNK_STATUS } from "../reduxConstants/async.constants";
import { uploadAsync, calculateAsync } from "../asyncThunks";

const initialState = {
	fileUploaded: false,
	fileProcessed: false,
	calculationInProgress: false,
	calculationReqCount: 0,
	calculationResult: {},
	processedData: {},
};

export const ModelSlice = createSlice({
	name: "model",
	initialState,
	reducers: {
		clearModelData: () => initialState,
		clearModelResult: (state, action) => {
			state.calculationReqCount = 0;
			state.calculationInProgress = false;
			state.calculationResult = {}				
		}
	},
	extraReducers: (builder) => {
		builder.addCase(uploadAsync.pending, (state, action) => {
			state.fileUploaded = true;
			state.fileProcessed = false;
		});
		builder.addCase(uploadAsync.fulfilled, (state, action) => {
			state.fileUploaded = true;
			state.fileProcessed = true;
			state.processedData = JSON.parse(action.payload.data.data); // all data-point json file paths are stored here	

		});
		builder.addCase(uploadAsync.rejected, (state, action) => {
			state.fileUploaded = false;
			state.fileProcessed = false;
		});
		builder.addCase(calculateAsync.pending, (state, action) => {
			state.calculationInProgress = true;
			state.calculationReqCount += 1;
			state.calculationResult = { ...state.calculationResult, ...action.meta.arg }
			state.calculationResult[`${action.meta.arg.Model}_Status_${action.meta.arg.Year}`] = "LOADING";
			state.calculationResult[`${action.meta.arg.Model}_Score_${action.meta.arg.Year}`] = '-';
			state.calculationResult[`${action.meta.arg.Model}_prob_${action.meta.arg.Year}(%)`] = '-';
			state.calculationResult[`${action.meta.arg.Model}_Category_${action.meta.arg.Year}`] = '-';
		});
		builder.addCase(calculateAsync.fulfilled, (state, action) => {
			state.calculationReqCount -= 1;
			const jsonResult = JSON.parse(action.payload.data.data); // all data-point json file paths are stored here	
			state.calculationResult["CKD_stage"] = jsonResult[0].CKD_stage;
			state.calculationResult[`${action.meta.arg.Model}_Status_${action.meta.arg.Year}`] = "DONE";
			state.calculationResult[`${action.meta.arg.Model}_Score_${action.meta.arg.Year}`] = jsonResult[0][`${action.meta.arg.Model}_Score_${action.meta.arg.Year}`];
			state.calculationResult[`${action.meta.arg.Model}_prob_${action.meta.arg.Year}(%)`] = jsonResult[0][`${action.meta.arg.Model}_prob_${action.meta.arg.Year}(%)`];
			state.calculationResult[`${action.meta.arg.Model}_Category_${action.meta.arg.Year}`] = jsonResult[0][`${action.meta.arg.Model}_Category_${action.meta.arg.Year}`];
			if (state.calculationReqCount === 0) {
				state.calculationInProgress = false;
			}
		});
		builder.addCase(calculateAsync.rejected, (state, action) => {
			state.calculationReqCount -= 1;
			const jsonResult = JSON.parse(action.payload.data.data); // all data-point json file paths are stored here	
			state.calculationResult["CKD_stage"] = jsonResult[0].CKD_stage;
			state.calculationResult[`${action.meta.arg.Model}_Status_${action.meta.arg.Year}`] = "FAIL";
			state.calculationResult[`${action.meta.arg.Model}_Score_${action.meta.arg.Year}`] = jsonResult[0][`${action.meta.arg.Model}_Score_${action.meta.arg.Year}`];
			state.calculationResult[`${action.meta.arg.Model}_prob_${action.meta.arg.Year}(%)`] = jsonResult[0][`${action.meta.arg.Model}_prob_${action.meta.arg.Year}(%)`];
			state.calculationResult[`${action.meta.arg.Model}_Category_${action.meta.arg.Year}`] = jsonResult[0][`${action.meta.arg.Model}_Category_${action.meta.arg.Year}`];
			if (state.calculationReqCount === 0) {
				state.calculationInProgress = false;
			}
		});
	}
});

export const { clearModelData, clearModelResult } = ModelSlice.actions;

export default ModelSlice.reducer;

import { Logo } from "../assets";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import styles from "./mainLayout.module.css";
import {clearAuth} from "../redux/slices/auth.slices"
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { Logout } from "@mui/icons-material";

export const MainLayout = () => {
    const dispatch = useDispatch();
    const { msInstance } = useSelector((state) => state.auth);
    const handleSubmit = (err, data, msal) => {
        if (msInstance?.logout) msInstance.logout();

        dispatch(clearAuth())
    };

    return (
        <>
            <Box>
                <AppBar position="static" sx={{ boxShadow: "none" }}>
                    <Box className={styles.leftlayoutContainer}>
                        <div className={styles.logoContainer}>
                            <img src={Logo} className={styles.logo} alt="" />
                        </div>
                        <div className={styles.titleContainer}>
                            Kidney Disease Progression Prediction
                        </div>
                        <div className={styles.buttonContainer}>
                            <Button color="secondary" variant="contained" startIcon={<Logout />} onClick={handleSubmit}>Logout</Button>
                        </div>
                    </Box>
                </AppBar>
            </Box>
            <div style={{ height: "100%" }}>
                <Box sx={{ p: "10px", bgcolor: "secondary.main", height: "100%" }}>
                    <Outlet />
                </Box>
            </div>
        </>
    )
};
import { METHODS, SERVICE_ROUTES } from "../reduxConstants/service.constants";
import Axios from "axios";

export function uploadService(payload) {
    return new Promise((resolve, reject) => {
        // Create a FormData object to send the file
        let formData = new FormData();
        formData.append('file', payload.file); // Assuming 'file' is the key name expected by the server
        formData.append('accessToken', payload.accessToken)

        // Configuration for the Axios request
        let config = {
            url: SERVICE_ROUTES.UPLOAD, // Update with your file upload endpoint
            method: METHODS.POST,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data', // Use multipart form data for file upload
            },
        };

        // Making the Axios request
        Axios.request(config)
            .then((response) => {
                // Resolve the Promise with the response
                return resolve(response);
            })
            .catch((error) => {
                // Reject the Promise with the error
                return reject(error);
            });
    });
}

export function calculateService(data) {
    return new Promise((resolve, reject) => {
        let config = {
            url: SERVICE_ROUTES.CALCULATE,
            method: METHODS.POST,
            maxBodyLength: Infinity,
            data,
            headers: {
                "Content-Type": "application/json",
            },
        };
        Axios.request(config)
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	authToken: null,
	msInstance: null
};

export const AuthSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		clearAuth: () => initialState,
		setAuth: (state, action) => {
			state.authToken =action.payload.accessToken
			state.msInstance = action.payload.msInstance
		}
	},
});

export const {
	setAuth,
	clearAuth
} = AuthSlice.actions;

export default AuthSlice.reducer;
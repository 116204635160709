import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

// Styled input element with visual hiding
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// Main component for file upload button
export default function InputFileUpload({ handleOnChange }) {
  const fileInputRef = React.useRef(null);

  const handleClick = () => {
    // Reset the value of the file input to clear the selection
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <Button
      color='secondary'
      component="label"  // Renders the button as a label
      role={undefined}
      variant="contained" // Button variant
      tabIndex={-1} // Make the button not focusable
      startIcon={<DownloadIcon />} // Icon displayed before the button text
      onClick={handleClick} // Handle button click to clear input
    >
      IMPORT DATA {/* Button text */}
      {/* Hidden input element for file selection */}
      <VisuallyHiddenInput
        type="file"
        accept=".csv"
        onChange={handleOnChange}
        ref={fileInputRef} // Reference to the input element
      />
    </Button>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import rootReducers from "../slices";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import localforage from 'localforage'

const persistConfig = {
	key: "root",
	version: 1,
	storage: localforage,
	whiteList: ["model"],
};

export const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk],
});

export const persistor = persistStore(store);

import logo from './logo.svg';
import './App.css';
import { APP_ROUTES } from "./routes/app.routes";
import { AUTH_ROUTES } from "./routes/auth.routes";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";
import { BrowserRouter as Router } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector} from "react-redux";
import Axios from "axios";
import {
    clearModelData
} from "./redux/slices/model.slices";

import { useEffect } from 'react';

const RoutesComponent = ({token}) => {
	return useRoutes(token?APP_ROUTES:AUTH_ROUTES);
};

function App() {
	const dispatch = useDispatch();
	const baseUrl = process.env.REACT_APP_BASE_URL;
	Axios.defaults.baseURL = baseUrl;
	const { fileUploaded, fileProcessed, calculationInProgress } = useSelector((state) => state.model);
	const { authToken } = useSelector((state) => state.auth);
	
	useEffect(() => {
		const handleSessionClose = () => {
			dispatch(clearModelData());
		};
	
		window.addEventListener('beforeunload', handleSessionClose);
		window.addEventListener('unload', handleSessionClose);
	
		return () => {
			window.removeEventListener('beforeunload', handleSessionClose);
			window.removeEventListener('unload', handleSessionClose);
		};
	},[dispatch]);

	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={(fileUploaded && !fileProcessed) || calculationInProgress}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
				<RoutesComponent token={authToken}/>
			</Router>
		</ThemeProvider>
	);
}

export default App;

import {
    InputBase
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        maxheight: 40,
        // maxWidth: 193,
        borderRadius: 8,
        position: "relative",
        border: `2px solid ${theme.typography.body1.color}`,
        color: theme.typography.body1.color,
        fontSize: 15,
        fontWeight: "normal",
        padding: "8px 11px 8px 16px",
        fontFamily: "Poppins",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 8,
            borderColor: "none",
        },
    },
}));
 
import {htmlContent} from "../assets/html/pdf-report-english.html";
import cheerio from "cheerio";
import graphLines from "../assets/images/graph-lines_2x.svg"
import { isNull } from "lodash";

const LANGUAGE = "ENGLISH"
const content = {
    1: "KIDNEY DISEASE PROGRESSION PREDICTION REPORT",
    2: "Report date",
    3: "Data collection date",
    4: "PATIENT PROFILE",
    5: "Name",
    6: "Sex",
    7: "Birth Date",
    8: "Patient No.",
    9: "KEY CLINICAL INFORMATION",
    10: "CKD Stage",
    11: "eGFR",
    12: "Creatinine",
    13: "Blood Pressure",
    14: "Haemoglobin",
    15: "Blood Glucose",
    16: "Potassium",
    17: "Uric Acid",
    18: "Phosphorus",
    19: "COMORBIDITIES",
    20: "Diabetes",
    21: "Hypertension",
    22: "Cancer",
    23: "Coronary Artery Disease(CAD)",
    24: "PREDICTION RESULTS",
    25: "Risk of kidney rapid progression",
    26: "Risk of initiation of RRT",
    27: "Low",
    28: "Medium",
    29: "High",
    30: "Your risk is medium in 2 years but high in 5 years",
    31: "Your risk is low in the next 5 years",
    32: "Rapid Progression (RP) in kidney rapid progression occurs when one or more of the following conditions are observed: eGFR slope of ≥ 5 ml/min/1.73 m\\00B2/year; Sustained decrease in eGFR ≥ 40%, confirmed at least 3 months apart; Sustained eGFR < 15 ml/min/1.73 m&sup2. Initiation of RRT (IR) occurs when one of more of the following conditions are observed: Long-term dialysis; Kidney transplantation. The score correlates with the probability of outcomes in the study population. Risk classification guides interpretation of the risk score using cut-offs related to clinical outcomes for indicated time frame.",
    33: "Initiation of RRT (IR) occurs when one of more of the following conditions are observed: Long-term dialysis; Kidney transplantation.  The score correlates with the probability of outcomes in the study population. Risk classification guides interpretation of the risk score using cut-offs related to clinical outcomes for indicated time frame.",
    34: "CLINICAL GUIDANCE",
    35: `Your risk of rapid progression is <span style="color:#0ca923;"><b>Low</b></span> in next 5 years`,
    36: `Your risk of rapid progression is <span style="color:#0ca923;"><b>Low</b></span> in 2 years but <span style="color:#804200;"><b>Medium</b></span> in 5 years`,
    37: `Your risk of rapid progression is <span style="color:#0ca923;"><b>Low</b></span> in 2 years but <span style="color:#804200;"><b>Medium</b></span> in 2 years`,
    38: `Your risk of rapid progression is <span style="color:#0ca923;"><b>Low</b></span> in 1 year and <span style="color:#804200;"><b>Medium</b></span> in 2 years`,
    39: `Your risk of rapid progression is <span style="color:#0ca923;"><b>Low</b></span> in 2 years and <span style="color:#9e0101;"><b>High</b></span> in 5 years`,
    40: `Your risk of rapid progression is <span style="color:#804200;"><b>Medium</b></span> in 2 years and <span style="color:#9e0101;"><b>High</b></span> in 5 years`,
    41: `Your risk of rapid progression is <span style="color:#0ca923;"><b>Low</b></span> in 2 years but <span style="color:#9e0101;"><b>High</b></span> in 2 years`,
    42: `Your risk of rapid progression is <span style="color:#0ca923;"><b>Low</b></span> in 1 year and <span style="color:#9e0101;"><b>High</b></span> in 2 years`,
    43: `Your risk of rapid progression is <span style="color:#0ca923;"><b>Low</b></span> in 1 year and <span style="color:#9e0101;"><b>High</b></span> in 2 years`,
    44: `Your risk of rapid progression is <span style="color:#804200;"><b>Medium</b></span> in 1 year`,
    45: `Your risk of rapid progression is <span style="color:#804200;"><b>Medium</b></span> in 1 year`,
    46: `Your risk of rapid progression is <span style="color:#804200;"><b>Medium</b></span> in 1 year`,
    47: `Your risk of rapid progression is <span style="color:#804200;"><b>Medium</b></span> in 1 year but <span style="color:#9e0101;"><b>High</b></span> in 5 years`,
    48: `Your risk of rapid progression is <span style="color:#804200;"><b>Medium</b></span> in 1 year but <span style="color:#9e0101;"><b>High</b></span> in 5 years`,
    49: `Your risk of rapid progression is <span style="color:#804200;"><b>Medium</b></span> in 1 year but <span style="color:#9e0101;"><b>High</b></span> in 2 years`,
    50: `Your risk of rapid progression is <span style="color:#804200;"><b>Medium</b></span> in 1 year but <span style="color:#9e0101;"><b>High</b></span> in 2 years`,
    51: `Your risk of rapid progression is <span style="color:#804200;"><b>Medium</b></span> in 1 year but <span style="color:#9e0101;"><b>High</b></span> in 2 years`,
    52: `Your risk of rapid progression is <span style="color:#9e0101;"><b>High</b></span> in 1 year`,
    53: `Your risk of rapid progression is <span style="color:#9e0101;"><b>High</b></span> in 1 year`,
    54: `Your risk of rapid progression is <span style="color:#9e0101;"><b>High</b></span> in 1 year`,
    55: `Your risk of rapid progression is <span style="color:#9e0101;"><b>High</b></span> in 1 year`,
    56: `Your risk of rapid progression is <span style="color:#9e0101;"><b>High</b></span> in 1 year`,
    57: `Your risk of initiation of rrt is <span style="color:#0ca923;"><b>Low</b></span> for next 5 years`,
    58: `Your risk of initiation of rrt is <span style="color:#0ca923;"><b>Low</b></span> in 2 years but <span style="color:#804200;"><b>Medium</b></span> in 5 years`,
    59: `Your risk of initiation of rrt is <span style="color:#0ca923;"><b>Low</b></span> in 1 year and <span style="color:#804200;"><b>Medium</b></span> in 2 years`,
    60: `Your risk of initiation of rrt is <span style="color:#0ca923;"><b>Low</b></span> in 1 year and <span style="color:#804200;"><b>Medium</b></span> in 2 years`,
    61: `Your risk of initiation of rrt is <span style="color:#0ca923;"><b>Low</b></span> in 2 years and <span style="color:#9e0101;"><b>High</b></span> in 5 years`,
    62: `Your risk of initiation of rrt is <span style="color:#0ca923;"><b>Low</b></span> in 1 year and <span style="color:#9e0101;"><b>High</b></span> in 2 years`,
    63: `Your risk of initiation of rrt is <span style="color:#9e0101;"><b>High</b></span> in 2 years and <span style="color:#9e0101;"><b>High</b></span> in 5 years`,
    64: `Your risk of initiation of rrt is <span style="color:#0ca923;"><b>Low</b></span> in 1 year and <span style="color:#9e0101;"><b>High</b></span> in 2 years`,
    65: `Your risk of initiation of rrt is <span style="color:#0ca923;"><b>Low</b></span> in 1 year and <span style="color:#9e0101;"><b>High</b></span> in 2 years`,
    66: `Your risk of initiation of rrt is <span style="color:#804200;"><b>Medium</b></span> in 1 year`,
    67: `Your risk of initiation of rrt is <span style="color:#804200;"><b>Medium</b></span> in 1 year`,
    68: `Your risk of initiation of rrt is <span style="color:#804200;"><b>Medium</b></span> in 1 year`,
    69: `Your risk of initiation of rrt is <span style="color:#804200;"><b>Medium</b></span> in 1 year but <span style="color:#9e0101;"><b>High</b></span> in 5 years`,
    70: `Your risk of initiation of rrt is <span style="color:#804200;"><b>Medium</b></span> in 1 year but <span style="color:#9e0101;"><b>High</b></span> in 5 years`,
    71: `Your risk of initiation of rrt is <span style="color:#804200;"><b>Medium</b></span> in 1 year but <span style="color:#9e0101;"><b>High</b></span> in 2 years`,
    72: `Your risk of initiation of rrt is <span style="color:#804200;"><b>Medium</b></span> in 1 year but <span style="color:#9e0101;"><b>High</b></span> in 2 years`,
    73: `Your risk of initiation of rrt is <span style="color:#804200;"><b>Medium</b></span> in 1 year but <span style="color:#9e0101;"><b>High</b></span> in 2 years`,
    74: `Your risk of initiation of rrt is <span style="color:#9e0101;"><b>High</b></span> in 1 year`,
    75: `Your risk of initiation of rrt is <span style="color:#9e0101;"><b>High</b></span> in 1 year`,
    76: `Your risk of initiation of rrt is <span style="color:#9e0101;"><b>High</b></span> in 1 year`,
    77: `Your risk of initiation of rrt is <span style="color:#9e0101;"><b>High</b></span> in 1 year`,
    78: `Your risk of initiation of rrt is <span style="color:#9e0101;"><b>High</b></span> in 1 year`,
    79: "1 time in a year",
    80: "Continue monitoring, as indicated",
    81: "≤130mm Hg systolic & ≤80mm Hg diastolic",
    82: "Maintain target HbA1c of ~7%, unless contraindicated",
    83: "Recommend physical activity compatible with cardiovascular health and tolerance, achieve healthy weight and stop smoking",
    84: "2 or 3 times in a year",
    85: "Review current care management",
    86: "Suggest use of ARB, statins and antiplatelet therapy where clinically indicated",
    87: "Consider SGLT2 inhibitors if clinically indicated",
    88: "Consider RAAS therapy (ARB or ACEI or antihypertensives)",
    89: "Provide dietary education, tailored to severity of CKD and need to intervene on salt, phosphate, potassium, protein intake where indicated",
    90: ">3 times in a year",
    91: "Continue care under nephrologist",
    92: "≤140mm Hg systolic & ≤90mm Hg diastolic",
    93: "Consider RAAS therapy (ARB or ACEI or antihypertensives) to maximally tolerated dose",
    94: "1 or 2 times in a year",
    95: "Not needed",
    96: "Educate on dialysis options and transplant, as needed",
    97: "Start planning for dialysis or transplant, with appropriate education and counsel on",
    98: "diet,",
    99: "different RRT modalities,",
    100: "transplant options,",
    101: "vascular access surgery, and",
    102: "ethical, physiological and social care",
    103: "In 1 year",
    104: "In 2 years",
    105: "In 5 years",
    106: "Reference for clinical guidelines",
    107: "KDIGO 2012 Clinical Practice Guideline for the Evaluation and Management of Chronic Kidney Disease https://kdigo.org/wp-content/uploads/2017/02/KDIGO_2012_CKD_GL.pdf",
    108: "Consider RAAS therapy (ARB or ACEI or antihypertensives), as clinically indicated"
}

const FunctionDecline = {
    low:{
        MonitoringFrequency:content[79],
        NephrologyReferral:content[80],
        BloodPressureTarget:content[81],
        CVDRiskReduction:'--',
        BloodSugarControl:content[82],
        Diet:'--',
        BLOOD_PRESSURE_MANAGEMENT:content[108],
        Lifestyle:content[83]
    },
    medium:{
        MonitoringFrequency:content[84],
        NephrologyReferral:content[85],
        BloodPressureTarget:content[81],
        CVDRiskReduction:content[86],
        BloodSugarControl:content[87],
        BLOOD_PRESSURE_MANAGEMENT:content[88],
        Diet:content[89],
        Lifestyle:content[83]
    },
    high:{
        MonitoringFrequency:content[90],
        NephrologyReferral:content[91],
        BloodPressureTarget:content[81],
        CVDRiskReduction:content[86],
        BloodSugarControl:content[87],
        BLOOD_PRESSURE_MANAGEMENT:content[93],
        Diet:content[89],
        Lifestyle:content[83]
    },
    low_modified: {
        MonitoringFrequency:content[94],
        NephrologyReferral:content[80],
        BloodPressureTarget:content[81],
        CVDRiskReduction:content[86],
        BloodSugarControl:content[87],
        BLOOD_PRESSURE_MANAGEMENT:content[88],
        Diet:content[89],
        Lifestyle:content[83]
    }
};

const RRT = {
    low:{
        RRTPreparation:{
            Text:content[95],
            SubText:null,
            ListItems:[]
        }
    },
    medium:{
        RRTPreparation:{
            Text:content[96],
            SubText:null,
            ListItems:[]
        }
    },
    high:{
        RRTPreparation:{
            Text:content[97],
            SubText:null,
            ListItems: [
                content[98],
                content[99],
                content[100],
                content[101],
                content[102]
            ]
        }
    }
};

const GraphWidth = {
    FD: {
        5: {
            low: {
                width: 31.47,
                label: 30
            },
            medium: {
                width: 34,
                label: 64
            },
            high: {
                width: 36.45,
                label: 65
            }
        },
        2: {
            low: {
                width: 31.4,
                label: 30
            },
            medium: {
                width: 38.9,
                label: 69
            },
            high: {
                width: 31.4,
                label: 70
            }
        },
        1: {
            low: {
                width: 31.55,
                label: 30
            },
            medium: {
                width: 49,
                label: 79
            },
            high: {
                width: 21.55,
                label: 80
            }
        }
    },
    RRT: {
        5: {
            low: {
                width: 31.3,
                label: 30
            },
            medium: {
                width: 34,
                label: 64
            },
            high: {
                width: 36.2,
                label: 65
            }
        },
        2: {
            low: {
                width: 46,
                label: 45
            },
            medium: {
                width: 34,
                label: 79
            },
            high: {
                width: 21.4,
                label: 80
            }
        },
        1: {
            low: {
                width: 51,
                label: 50
            },
            medium: {
                width: 39,
                label: 89
            },
            high: {
                width: 11.6,
                label: 90
            }
        }
    }
};

const tickSvg = {
    high: `
    
<svg width="33" height="49" viewBox="0 0 33 49" xmlns="http://www.w3.org/2000/svg">
<g fill="none" fill-rule="evenodd">
    <path d="M16.334 49C5.444 33.657 0 22.959 0 16.906 0 7.827 5.52 0 16.797 0 28.073 0 33 8.331 33 16.906 33 22.623 27.445 33.321 16.334 49z" fill="#888"/>
    <circle stroke="#9E0101" stroke-width=".718" fill="#FEEFEF" cx="16.5" cy="16" r="12.641"/>
</g>
</svg>

    `,
    low: `
  
<svg width="33" height="49" viewBox="0 0 33 49" xmlns="http://www.w3.org/2000/svg">
<g fill="none" fill-rule="evenodd">
    <path d="M16.334 49C5.444 33.657 0 22.959 0 16.906 0 7.827 5.52 0 16.797 0 28.073 0 33 8.331 33 16.906 33 22.623 27.445 33.321 16.334 49z" fill="#888"/>
    <circle stroke="#0CA923" stroke-width=".718" fill="#EDFFF0" cx="16.5" cy="16" r="12.641"/>
</g>
</svg>

    `,
    medium: `
   
<svg width="33" height="49" viewBox="0 0 33 49" xmlns="http://www.w3.org/2000/svg">
<g fill="none" fill-rule="evenodd">
    <path d="M16.334 49C5.444 33.657 0 22.959 0 16.906 0 7.827 5.52 0 16.797 0 28.073 0 33 8.331 33 16.906 33 22.623 27.445 33.321 16.334 49z" fill="#888"/>
    <circle stroke="#804200" stroke-width=".718" fill="#FFF7E3" cx="16.5" cy="16" r="12.641"/>
</g>
</svg>

    `
}


export const renderHtmlWithJsonData = (record) => {

    const $ = cheerio.load(htmlContent);
    const $style = $('style');

    let egfrValues = record.previousEGFR;
    let data = [];
    let labels = [];
    let keys = Object.keys(egfrValues);

    if (keys.length > 3) {
        keys = keys.slice(-3)
    }

    for (let i = 0; i < keys.length; i++) {
        data.push(parseFloat(egfrValues[keys[i]].eGFR) / egfrValues[keys[i]].count);
        labels.push(egfrValues[keys[i]].date);
    }

    // console.log(`Graph data for Patient No: ${record.PatientProfileInfo.PatientNo}`, data, labels);
    // const chartData = {
    //     labels: labels,
    //     datasets: [{
    //         data: data,
    //         backgroundColor: 'rgb(8 155 171)',
    //         borderColor: 'rgb(8 155 171)',
    //         barPercentage: 0.3,
    //         categoryPercentage: keys.length === 1 ? 0.5 : keys.length === 2 ? 0.6 : 0.8
    //     }]
    // };

    // let eGFRGraph = createImage(chartData);
    // $('#eGFRGraph').append(`<img style="width: 100%;height: 100%;object-fit: contains;" src="${eGFRGraph}" />`);


    let templateKeys = [
        "PatientProfileInfo-Name",
        "PatientProfileInfo-Sex",
        "PatientProfileInfo-DOB",
        "PatientProfileInfo-ID_Medical_Record",
        "KeyClinicalParameters-CKD_Stage",
        "KeyClinicalParameters-Latest_eGFR",
        "KeyClinicalParameters-Creatinine",
        "KeyClinicalParameters-Blood_Pressure",
        "KeyClinicalParameters-Hemoglobin",
        "KeyClinicalParameters-Blood_Glucose",
        "KeyClinicalParameters-Phosphorus",
        "KeyClinicalParameters-Uric_Acid",
        "KeyClinicalParameters-Potassium",
        "ClinicalGuidance-FunctionDecline-MonitoringFrequency",
        "ClinicalGuidance-FunctionDecline-NephrologyReferral",
        "ClinicalGuidance-FunctionDecline-BloodPressureTarget",
        "ClinicalGuidance-FunctionDecline-CVDRiskReduction",
        "ClinicalGuidance-FunctionDecline-BloodSugarControl",
        "ClinicalGuidance-FunctionDecline-BLOOD_PRESSURE_MANAGEMENT",
        "ClinicalGuidance-FunctionDecline-Diet",
        "ClinicalGuidance-FunctionDecline-Lifestyle",
        "ClinicalGuidance-RRT-RRTPreparation-Text",
        "ClinicalGuidance-RRT-RRTPreparation-SubText",
        "ClinicalGuidance-RRT-RRTPreparation-ListItems"
    ];

    let comorbiditiesKeys = [
        "Comorbidities-Diabetes",
        "Comorbidities-Hypertension",
        "Comorbidities-Cancer",
        "Comorbidities-Cardiovascular_Disease",
    ]

    comorbiditiesKeys.map((key) => {
        let value = record;
        key.split("-").map((e) => {
            if (value[e]) {
                value = value[e];
            }
            return {}
        });
        if (value === 1) {
            $(`#${key}_box`).append(`<svg width="20" height="20" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fill-rule="evenodd">
                                            <path d="M0 0h12.003v12.003H0z"/>
                                            <circle stroke="#9E0101" stroke-width=".75" fill="#9E0101" stroke-linecap="round" stroke-linejoin="round" cx="6.002" cy="6.002" r="4.501"/>
                                            <path stroke="#FFF" stroke-width=".75" stroke-linecap="round" stroke-linejoin="round" d="m4.501 6.002 1 1 2.001-2"/>
                                        </g>
                                    </svg>`);
            $(`#${key}`).css('color', '#9e0101');
        } else {
            $(`#${key}_box`).append(`<svg width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fill-rule="evenodd">
                                            <path d="M0 0h15.995v16H0z"/>
                                            <ellipse stroke="#8F8D8D" stroke-linecap="round" stroke-linejoin="round" cx="7.998" cy="8" rx="5.998" ry="6"/>
                                        </g>
                                    </svg>`);
            $(`#${key}`).css('color', '#333');
        }
        return {}
    });

    templateKeys.map((key) => {
        let value = record;
        key.split("-").map((e) => {
            if (value[e]) {
                value = value[e];
            }
            return {}
        });
        if (key === "ClinicalGuidance-RRT-RRTPreparation-ListItems") {
            if (value.length > 0) {
                for (let item of value) {
                    if (LANGUAGE === "CHINESE") {
                        $(`#${key}`).append(`<li style="width: 50%; font-size:10px;font-weight: normal;font-family: 'Noto Sans';margin-bottom: 3px;">${item}</li>`);
                    } else {
                        $(`#${key}`).append(`<li style="width: 50%; font-size:10px;font-weight: normal;font-family: 'Poppins', sans-serif;margin-bottom: 3px;">${item}</li>`);
                    }
                }
            }
        } else {
            $(`#${key}`).text(value);
        }
        return {}
    });

    if (record.PatientProfileInfo.Sex.toLowerCase() === "male") {
        $(`#range-Male`).css('display', `block`);
    }

    if (record.PatientProfileInfo.Sex.toLowerCase() === "female") {
        $(`#range-Female`).css('display', `block`);
    }

    if (record.KeyClinicalParameters.Blood_Pressure_out_of_range) {
        $(`#KeyClinicalParameters-Blood_Pressure`).css('color', '#9E0101')
        $(`#KeyClinicalParameters-Blood_Pressure-Lbl`).css('color', '#9E0101')
        $(`#KeyClinicalParameters-Blood_Pressure-Unit`).css('color', '#9E0101')
    }

    if (record.KeyClinicalParameters.Hemoglobin_out_of_range) {
        $(`#KeyClinicalParameters-Hemoglobin`).css('color', '#9E0101')
        $(`#KeyClinicalParameters-Hemoglobin-Lbl`).css('color', '#9E0101')
        $(`#KeyClinicalParameters-Hemoglobin-Unit`).css('color', '#9E0101')
    }

    if (record.KeyClinicalParameters.Blood_Glucose_out_of_range) {
        $(`#KeyClinicalParameters-Blood_Glucose`).css('color', '#9E0101')
        $(`#KeyClinicalParameters-Blood_Glucose-Lbl`).css('color', '#9E0101')
        $(`#KeyClinicalParameters-Blood_Glucose-Unit`).css('color', '#9E0101')
    }

    if (record.KeyClinicalParameters.Phosphorus_out_of_range) {
        $(`#KeyClinicalParameters-Phosphorus`).css('color', '#9E0101')
        $(`#KeyClinicalParameters-Phosphorus-Lbl`).css('color', '#9E0101')
        $(`#KeyClinicalParameters-Phosphorus-Unit`).css('color', '#9E0101')
    }

    if (record.KeyClinicalParameters.Uric_Acid_out_of_range) {
        $(`#KeyClinicalParameters-Uric_Acid`).css('color', '#9E0101')
        $(`#KeyClinicalParameters-Uric_Acid-Lbl`).css('color', '#9E0101')
        $(`#KeyClinicalParameters-Uric_Acid-Unit`).css('color', '#9E0101')
    }

    if (record.KeyClinicalParameters.Potassium_out_of_range) {
        $(`#KeyClinicalParameters-Potassium`).css('color', '#9E0101')
        $(`#KeyClinicalParameters-Potassium-Lbl`).css('color', '#9E0101')
        $(`#KeyClinicalParameters-Potassium-Unit`).css('color', '#9E0101')
    }
    //progress bar manipulation
    let isRPDataPresent = true;
    if (!record.RPProgressBarData) {
        isRPDataPresent = false
    }

    $('.progressbar-scale').append(`<img src="${graphLines}" style="width: 94%; object-fit: contain;margin: 0 auto ;position: relative;bottom:5px;display:flex;"/>`)
    if (isRPDataPresent) {
        //render both progress bar
        //Function Decline Progress bar
        $(`#twoBars`).css('display', `block`);
        $(`#twoBars-FootText`).css('display', `block`);
        $(`#twoBars-FootText`).css('margin-top', `15px`);
        $(`#singleBar-FootText`).css('display', `block`);
        $(`#progressive_meter_tip_twobar_RP`).append(tickSvg[record.RPProgressBarData.severity.toLowerCase()])
        $(`#RPProgressBarData-year`).text(record.RPProgressBarData.year === 1 ? content[103] : record.RPProgressBarData.year === 2 ? content[104] : content[105]);
        $(`#RPProgressBarData-score`).text(record.RPProgressBarData.score);
        $("#RP_progress_bar_tick_container").css('left', `calc(${record.RPProgressBarData.score}% - 19px)`);
        if (record.RPProgressBarData.footnote !== 'None') {
            if (LANGUAGE === "CHINESE") {
                $(`#RPProgressBarData-footnote`).append(`<p style="font-size:9px;font-family: 'Kato Sans';margin-top: 25px; text-align:center;">${record.RPProgressBarData.footnote}</p>`);
            } else {
                $(`#RPProgressBarData-footnote`).append(`<p style="font-size:9px;font-family: 'Poppins';margin-top: 25px; text-align:center;">${record.RPProgressBarData.footnote}</p>`);
            }
        }

        $("#FD-low-width").css('width', `${GraphWidth['FD'][record.RPProgressBarData.year]['low']['width']}%`);
        $("#FD-medium-width").css('width', `${GraphWidth['FD'][record.RPProgressBarData.year]['medium']['width']}%`);
        $("#FD-high-width").css('width', `${GraphWidth['FD'][record.RPProgressBarData.year]['high']['width']}%`);
        $("#FD-low-label").text(GraphWidth['FD'][record.RPProgressBarData.year]['low']['label'])
        $("#FD-medium-label").text(GraphWidth['FD'][record.RPProgressBarData.year]['high']['label'])

        //RRT Progress bar
        $(`#progressive_meter_tip_twobar_PE`).append(tickSvg[record.PEProgressBarData.severity.toLowerCase()])

        $(`#PEProgressBarData-year`).text(record.PEProgressBarData.year === 1 ? content[103] : record.PEProgressBarData.year === 2 ? content[104] : content[105]);
        $(`#PEProgressBarData-score`).text(record.PEProgressBarData.score);
        $("#PE_progress_bar_tick_container").css('left', `calc(${record.PEProgressBarData.score}% - 19px)`);

        if (record.PEProgressBarData.footnote !== 'None') {
            if (LANGUAGE === "CHINESE") {
                $(`#PEProgressBarData-footnote`).append(`<p style="font-size:9px;font-family: 'Kato Sans';margin-top: 25px; text-align:center;">${record.PEProgressBarData.footnote}</p>`);
            } else {
                $(`#PEProgressBarData-footnote`).append(`<p style="font-size:9px;font-family: 'Poppins';margin-top: 25px; text-align:center;">${record.PEProgressBarData.footnote}</p>`);
            }
        }
        $("#RRT-low-width").css('width', `${GraphWidth['RRT'][record.PEProgressBarData.year]['low']['width']}%`);
        $("#RRT-medium-width").css('width', `${GraphWidth['RRT'][record.PEProgressBarData.year]['medium']['width']}%`);
        $("#RRT-high-width").css('width', `${GraphWidth['RRT'][record.PEProgressBarData.year]['high']['width']}%`);
        $("#RRT-low-label").text(GraphWidth['RRT'][record.PEProgressBarData.year]['low']['label'])
        $("#RRT-medium-label").text(GraphWidth['RRT'][record.PEProgressBarData.year]['high']['label'])
    } else {
        //render one progress bar
        //RRT Progress bar
        $(`#singleBar`).css('display', `block`);
        $(`#singleBar-FootText`).css('display', `block`);
        $(`#singleBar-FootText`).css('margin-top', `15px`);
        $(`#PEProgressBarData-year`).text(record.PEProgressBarData.year === 1 ? content[103] : record.PEProgressBarData.year === 2 ? content[104] : content[105]);
        $(`#PEProgressBarData-score`).text(record.PEProgressBarData.score);
        $(`#progressive_meter_tip_singlebar_PE`).append(tickSvg[record.PEProgressBarData.severity.toLowerCase()])
        $("#PE_progress_bar_tick_container").css('left', `calc(${record.PEProgressBarData.score}% - 19px)`);
        if (record.PEProgressBarData.footnote !== 'None') {
            if (LANGUAGE === "CHINESE") {
                $(`#PEProgressBarData-footnote`).append(`<p style="font-size:9px;font-family: 'Kato Sans';margin-top: 25px; text-align:center;">${record.PEProgressBarData.footnote}</p>`);
            } else {
                $(`#PEProgressBarData-footnote`).append(`<p style="font-size:9px;font-family: 'Poppins';margin-top: 25px; text-align:center;">${record.PEProgressBarData.footnote}</p>`);
            }
        }

        $("#singleBar-low-width").css('width', `${GraphWidth['RRT'][record.PEProgressBarData.year]['low']['width']}%`);
        $("#singleBar-medium-width").css('width', `${GraphWidth['RRT'][record.PEProgressBarData.year]['medium']['width']}%`);
        $("#singleBar-high-width").css('width', `${GraphWidth['RRT'][record.PEProgressBarData.year]['high']['width']}%`);
        $("#singleBar-low-label").text(GraphWidth['RRT'][record.PEProgressBarData.year]['low']['label'])
        $("#singleBar-medium-label").text(GraphWidth['RRT'][record.PEProgressBarData.year]['high']['label'])
    }

    //return rendered html
    return $.html();
}

function generatePreviousEGFR(ele) {
    let previousEGFR = {};
    return previousEGFR;
}

function changeDateFormat(date) {
    try {
        let dateToBeChanged = new Date(date);
        return `${dateToBeChanged.getFullYear()}/${('0' + parseInt(dateToBeChanged.getMonth() + 1)).slice(-2)}/${('0' + dateToBeChanged.getDate()).slice(-2)}`;
    } catch(err) {
        throw new Error(err,"Error sent in changeDateFormat Function")
    }
}

function sortObj(obj) {
    try{
        return Object.keys(obj).sort().reduce(function (result, key) {
            result[key] = obj[key];
            return result;
          }, {});
    }catch(err){
        throw new Error(err,"Error sent in sortObj Function")
    }  
    
}

/**
 * Rules for Function Decline footnote
 * @param {*} data 
 */
function getRPData(data) {
    try{
        if (data?.RP_pilot_Category_1y === 'Low' && data?.RP_pilot_Category_2y === 'Low' && data?.RP_pilot_Category_5y === 'Low') {
            return { year: 1, severity: "Low", score: data.RP_pilot_Score_1y, footnote: content[35] }
        } else if (data?.RP_pilot_Category_1y === 'Low' && data?.RP_pilot_Category_2y === 'Low' && data?.RP_pilot_Category_5y === 'Moderate') {
            return { year: 5, severity: "Medium", score: data.RP_pilot_Score_5y, footnote: content[36] }
        } else if (data?.RP_pilot_Category_1y === 'Low' && data?.RP_pilot_Category_2y === 'Moderate' && data?.RP_pilot_Category_5y === 'Moderate') {
            return { year: 2, severity: "Medium", score: data.RP_pilot_Score_2y, footnote:  content[37] }
        } else if (data?.RP_pilot_Category_1y === 'Low' && data?.RP_pilot_Category_2y === 'Moderate' && data?.RP_pilot_Category_5y === 'Low') {
            return { year: 2, severity: "Medium", score: data.RP_pilot_Score_2y, footnote:  content[38] }
        } else if (data?.RP_pilot_Category_1y === 'Low' && data?.RP_pilot_Category_2y === 'Low' && data?.RP_pilot_Category_5y === 'High') {
            return { year: 5, severity: "High", score: data.RP_pilot_Score_5y, footnote: content[39] }
        } else if (data?.RP_pilot_Category_1y === 'Low' && data?.RP_pilot_Category_2y === 'Moderate' && data?.RP_pilot_Category_5y === 'High') {
            return { year: 5, severity: "High", score: data.RP_pilot_Score_5y, footnote: content[40] }
        } else if (data?.RP_pilot_Category_1y === 'Low' && data?.RP_pilot_Category_2y === 'High' && data?.RP_pilot_Category_5y === 'High') {
            return { year: 2, severity: "High", score: data.RP_pilot_Score_2y, footnote: content[41] }
        } else if (data?.RP_pilot_Category_1y === 'Low' && data?.RP_pilot_Category_2y === 'High' && data?.RP_pilot_Category_5y === 'Moderate') {
            return { year: 2, severity: "High", score: data.RP_pilot_Score_2y, footnote: content[42] }
        } else if (data?.RP_pilot_Category_1y === 'Low' && data?.RP_pilot_Category_2y === 'High' && data?.RP_pilot_Category_5y === 'Low') {
            return { year: 2, severity: "High", score: data.RP_pilot_Score_2y, footnote: content[43] }
        } else if (data?.RP_pilot_Category_1y === 'Moderate' && data?.RP_pilot_Category_2y === 'Low' && data?.RP_pilot_Category_5y === 'Low') {
            return { year: 1, severity: "Medium", score: data.RP_pilot_Score_1y, footnote: content[44] }
        } else if (data?.RP_pilot_Category_1y === 'Moderate' && data?.RP_pilot_Category_2y === 'Low' && data?.RP_pilot_Category_5y === 'Moderate') {
            return { year: 1, severity: "Medium", score: data.RP_pilot_Score_1y, footnote: content[44] }
        } else if (data?.RP_pilot_Category_1y === 'Moderate' && data?.RP_pilot_Category_2y === 'Moderate' && data?.RP_pilot_Category_5y === 'Moderate') {
            return { year: 1, severity: "Medium", score: data.RP_pilot_Score_1y, footnote: content[45] }
        } else if (data?.RP_pilot_Category_1y === 'Moderate' && data?.RP_pilot_Category_2y === 'Moderate' && data?.RP_pilot_Category_5y === 'Low') {
            return { year: 1, severity: "Medium", score: data.RP_pilot_Score_1y, footnote: content[46] }
        } else if (data?.RP_pilot_Category_1y === 'Moderate' && data?.RP_pilot_Category_2y === 'Low' && data?.RP_pilot_Category_5y === 'High') {
            return { year: 5, severity: "High", score: data.RP_pilot_Score_5y, footnote: content[47] }
        } else if (data?.RP_pilot_Category_1y === 'Moderate' && data?.RP_pilot_Category_2y === 'Moderate' && data?.RP_pilot_Category_5y === 'High') {
            return { year: 5, severity: "High", score: data.RP_pilot_Score_5y, footnote: content[48] }
        } else if (data?.RP_pilot_Category_1y === 'Moderate' && data?.RP_pilot_Category_2y === 'High' && data?.RP_pilot_Category_5y === 'High') {
            return { year: 2, severity: "High", score: data.RP_pilot_Score_2y, footnote: content[49] }
        } else if (data?.RP_pilot_Category_1y === 'Moderate' && data?.RP_pilot_Category_2y === 'High' && data?.RP_pilot_Category_5y === 'Moderate') {
            return { year: 2, severity: "High", score: data.RP_pilot_Score_2y, footnote: content[50] }
        } else if (data?.RP_pilot_Category_1y === 'Moderate' && data?.RP_pilot_Category_2y === 'High' && data?.RP_pilot_Category_5y === 'Low') {
            return { year: 2, severity: "High", score: data.RP_pilot_Score_2y, footnote: content[51] }
        } else if (data?.RP_pilot_Category_1y === 'High' && data?.RP_pilot_Category_2y === 'Low' && data?.RP_pilot_Category_5y === 'Low') {
            return { year: 1, severity: "High", score: data.RP_pilot_Score_1y, footnote: content[52] }
        } else if (data?.RP_pilot_Category_1y === 'High' && data?.RP_pilot_Category_2y === 'Low' && data?.RP_pilot_Category_5y === 'Moderate') {
            return { year: 1, severity: "High", score: data.RP_pilot_Score_1y, footnote: content[52] }
        } else if (data?.RP_pilot_Category_1y === 'High' && data?.RP_pilot_Category_2y === 'Moderate' && data?.RP_pilot_Category_5y === 'Moderate') {
            return { year: 1, severity: "High", score: data.RP_pilot_Score_1y, footnote: content[52] }
        } else if (data?.RP_pilot_Category_1y === 'High' && data?.RP_pilot_Category_2y === 'Moderate' && data?.RP_pilot_Category_5y === 'Low') {
            return { year: 1, severity: "High", score: data.RP_pilot_Score_1y, footnote: content[52] }
        } else if (data?.RP_pilot_Category_1y === 'High' && data?.RP_pilot_Category_2y === 'Low' && data?.RP_pilot_Category_5y === 'High') {
            return { year: 1, severity: "High", score: data.RP_pilot_Score_1y, footnote: content[52] }
        } else if (data?.RP_pilot_Category_1y === 'High' && data?.RP_pilot_Category_2y === 'Moderate' && data?.RP_pilot_Category_5y === 'High') {
            return { year: 1, severity: "High", score: data.RP_pilot_Score_1y, footnote: content[53] }
        } else if (data?.RP_pilot_Category_1y === 'High' && data?.RP_pilot_Category_2y === 'High' && data?.RP_pilot_Category_5y === 'High') {
            return { year: 1, severity: "High", score: data.RP_pilot_Score_1y, footnote: content[54] }
        } else if (data?.RP_pilot_Category_1y === 'High' && data?.RP_pilot_Category_2y === 'High' && data?.RP_pilot_Category_5y === 'Moderate') {
            return { year: 1, severity: "High", score: data.RP_pilot_Score_1y, footnote: content[55] }
        } else if (data?.RP_pilot_Category_1y === 'High' && data?.RP_pilot_Category_2y === 'High' && data?.RP_pilot_Category_5y === 'Low') {
            return { year: 1, severity: "High", score: data.RP_pilot_Score_1y, footnote: content[56] }
        }
    }catch(error){
        throw new Error(error,"ERROR_SENT_IN_getRPData fUNCTION")
    }

}

/**
 * Rules for Initiation RRT footnote
 * @param {*} data 
 */
function getPEData(data) {
    try{
        if (data?.IR_Category_1y === 'Low' && data?.IR_Category_2y === 'Low' && data?.IR_Category_5y === 'Low') {
            return { year: 1, severity: "Low", score: data.IR_Score_1y, footnote: content[57] }
        } else if (data?.IR_Category_1y === 'Low' && data?.IR_Category_2y === 'Low' && data?.IR_Category_5y === 'Moderate') {
            return { year: 5, severity: "Medium", score: data.IR_Score_5y, footnote: content[58] }
        } else if (data?.IR_Category_1y === 'Low' && data?.IR_Category_2y === 'Moderate' && data?.IR_Category_5y === 'Moderate') {
            return { year: 2, severity: "Medium", score: data.IR_Score_2y, footnote:  content[59] }
        } else if (data?.IR_Category_1y === 'Low' && data?.IR_Category_2y === 'Moderate' && data?.IR_Category_5y === 'Low') {
            return { year: 2, severity: "Medium", score: data.IR_Score_2y, footnote:  content[60] }
        } else if (data?.IR_Category_1y === 'Low' && data?.IR_Category_2y === 'Low' && data?.IR_Category_5y === 'High') {
            return { year: 5, severity: "High", score: data.IR_Score_5y, footnote: content[61] }
        } else if (data?.IR_Category_1y === 'Low' && data?.IR_Category_2y === 'Moderate' && data?.IR_Category_5y === 'High') {
            return { year: 5, severity: "High", score: data.IR_Score_5y, footnote: content[62] }
        } else if (data?.IR_Category_1y === 'Low' && data?.IR_Category_2y === 'High' && data?.IR_Category_5y === 'High') {
            return { year: 2, severity: "High", score: data.IR_Score_2y, footnote: content[63] }
        } else if (data?.IR_Category_1y === 'Low' && data?.IR_Category_2y === 'High' && data?.IR_Category_5y === 'Moderate') {
            return { year: 2, severity: "High", score: data.IR_Score_2y, footnote: content[64] }
        } else if (data?.IR_Category_1y === 'Low' && data?.IR_Category_2y === 'High' && data?.IR_Category_5y === 'Low') {
            return { year: 2, severity: "High", score: data.IR_Score_2y, footnote: content[65] }
        } else if (data?.IR_Category_1y === 'Moderate' && data?.IR_Category_2y === 'Low' && data?.IR_Category_5y === 'Low') {
            return { year: 1, severity: "Medium", score: data.IR_Score_1y, footnote: content[66] }
        } else if (data?.IR_Category_1y === 'Moderate' && data?.IR_Category_2y === 'Low' && data?.IR_Category_5y === 'Moderate') {
            return { year: 1, severity: "Medium", score: data.IR_Score_1y, footnote: content[66] }
        } else if (data?.IR_Category_1y === 'Moderate' && data?.IR_Category_2y === 'Moderate' && data?.IR_Category_5y === 'Moderate') {
            return { year: 1, severity: "Medium", score: data.IR_Score_1y, footnote: content[67] }
        } else if (data?.IR_Category_1y === 'Moderate' && data?.IR_Category_2y === 'Moderate' && data?.IR_Category_5y === 'Low') {
            return { year: 1, severity: "Medium", score: data.IR_Score_1y, footnote: content[68] }
        } else if (data?.IR_Category_1y === 'Moderate' && data?.IR_Category_2y === 'Low' && data?.IR_Category_5y === 'High') {
            return { year: 5, severity: "High", score: data.IR_Score_5y, footnote: content[69] }
        } else if (data?.IR_Category_1y === 'Moderate' && data?.IR_Category_2y === 'Moderate' && data?.IR_Category_5y === 'High') {
            return { year: 5, severity: "High", score: data.IR_Score_5y, footnote: content[70] }
        } else if (data?.IR_Category_1y === 'Moderate' && data?.IR_Category_2y === 'High' && data?.IR_Category_5y === 'High') {
            return { year: 2, severity: "High", score: data.IR_Score_2y, footnote: content[71] }
        } else if (data?.IR_Category_1y === 'Moderate' && data?.IR_Category_2y === 'High' && data?.IR_Category_5y === 'Moderate') {
            return { year: 2, severity: "High", score: data.IR_Score_2y, footnote: content[72] }
        } else if (data?.IR_Category_1y === 'Moderate' && data?.IR_Category_2y === 'High' && data?.IR_Category_5y === 'Low') {
            return { year: 2, severity: "High", score: data.IR_Score_2y, footnote: content[73] }
        } else if (data?.IR_Category_1y === 'High' && data?.IR_Category_2y === 'Low' && data?.IR_Category_5y === 'Low') {
            return { year: 1, severity: "High", score: data.IR_Score_1y, footnote: content[74] }
        } else if (data?.IR_Category_1y === 'High' && data?.IR_Category_2y === 'Low' && data?.IR_Category_5y === 'Moderate') {
            return { year: 1, severity: "High", score: data.IR_Score_1y, footnote: content[74] }
        } else if (data?.IR_Category_1y === 'High' && data?.IR_Category_2y === 'Moderate' && data?.IR_Category_5y === 'Moderate') {
            return { year: 1, severity: "High", score: data.IR_Score_1y, footnote: content[74] }
        } else if (data?.IR_Category_1y === 'High' && data?.IR_Category_2y === 'Moderate' && data?.IR_Category_5y === 'Low') {
            return { year: 1, severity: "High", score: data.IR_Score_1y, footnote: content[74] }
        } else if (data?.IR_Category_1y === 'High' && data?.IR_Category_2y === 'Low' && data?.IR_Category_5y === 'High') {
            return { year: 1, severity: "High", score: data.IR_Score_1y, footnote: content[74] }
        } else if (data?.IR_Category_1y === 'High' && data?.IR_Category_2y === 'Moderate' && data?.IR_Category_5y === 'High') {
            return { year: 1, severity: "High", score: data.IR_Score_1y, footnote: content[75] }
        } else if (data?.IR_Category_1y === 'High' && data?.IR_Category_2y === 'High' && data?.IR_Category_5y === 'High') {
            return { year: 1, severity: "High", score: data.IR_Score_1y, footnote: content[76] }
        } else if (data?.IR_Category_1y === 'High' && data?.IR_Category_2y === 'High' && data?.IR_Category_5y === 'Moderate') {
            return { year: 1, severity: "High", score: data.IR_Score_1y, footnote: content[77] }
        } else if (data?.IR_Category_1y === 'High' && data?.IR_Category_2y === 'High' && data?.IR_Category_5y === 'Low') {
            return { year: 1, severity: "High", score: data.IR_Score_1y, footnote: content[78] }
        }
    }catch(error){
        throw new Error(error,"ERROR_SENT_IN_getPEData fUNCTION")
    }

}

export const buildData = (ele) => {
    let previousEGFR = generatePreviousEGFR(ele);
    let blood_pressure_out_of_range = (ele?.SBP > 130 || ele?.DBP > 80) ? true : false;
    let uric_acid_out_of_range = false;
    if (ele?.sex?.toLowerCase() === 'm') {
        uric_acid_out_of_range = ele?.Blood_UA != null ? (ele?.Blood_UA < 6.5) ? true : false : false;
    }

    if (ele?.sex?.toLowerCase() === 'f') {
        uric_acid_out_of_range = ele?.Blood_UA != null ? (ele?.Blood_UA < 6.0) ? true : false : false;
    }
    let blood_glucose_out_of_range = (ele?.Blood_Glucose_Fasting > 126) ? true : false;
    let hemoglobin_out_of_range = ele?.Blood_Hb != null ? (ele?.Blood_Hb < 10) ? true : false : false;
    let phosphorous_out_of_range = ele?.Blood_P != null ? (ele?.Blood_P < 3.5) ? true : false : false;
    let potassium_out_of_range = ele?.Blood_K != null ? (ele?.Blood_K < 3.5 || ele?.Blood_K > 4.9) ? true : false : false;

    let dataTemp = {
        PatientProfileInfo: {
            Name: ele?.Name ? ele.Name : "-",
            Sex: !isNull(ele?.Sex) ? (ele.Sex === 1)?"Male":"Female" : "-",
            DOB: ele?.Birthday ? changeDateFormat(ele.Birthday) : "-",
            ID_Medical_Record: ele?.PatientNo ? ele.PatientNo : "-",
            PatientNo: ele?.patientno ? ele.patientno : "-",
            VisitNo: ele?.Visit_Seq
        },
        ReportDateData: {
            Report_date: ele?.Prediction_date ? changeDateFormat(ele.Prediction_date) : "-",
            Data_collection_date: ele?.VisitDate ? changeDateFormat(ele.VisitDate) : "-",
        },
        KeyClinicalParameters: {
            CKD_Stage: ele?.CKD_stage ? ele.CKD_stage : "-",
            Latest_eGFR: ele?.Baseline_eGFR ? parseFloat(ele.Baseline_eGFR).toFixed() : "-",
            Creatinine: `${ele?.Urine_Creatinine ? Number((Math.round(ele.Urine_Creatinine / 0.05) * 0.05)).toFixed(1) : '-'}`,
            Blood_Pressure: `${ele?.SBP ? parseInt(ele.SBP) : "-"}/${ele?.DBP ? parseInt(ele.DBP) : "-"}`,
            Blood_Pressure_out_of_range: blood_pressure_out_of_range,
            Hemoglobin: `${ele?.Blood_Hb ? ele.Blood_Hb : '-'}`,
            Hemoglobin_out_of_range: hemoglobin_out_of_range,
            Blood_Glucose: `${ele?.Blood_Glucose_Fasting ? ele.Blood_Glucose_Fasting : '-'}`,
            Blood_Glucose_out_of_range: blood_glucose_out_of_range,
            Phosphorus: `${ele?.Blood_P ? ele.Blood_P : '-'}`,
            Phosphorus_out_of_range: phosphorous_out_of_range,
            Uric_Acid: `${ele?.Blood_UA ? ele.Blood_UA : '-'}`,
            Uric_Acid_out_of_range: uric_acid_out_of_range,
            Potassium: `${ele?.Blood_K ? ele.Blood_K : '-'}`,
            Potassium_out_of_range: potassium_out_of_range
        },
        previousEGFR: sortObj(previousEGFR),
        Comorbidities: {
            Diabetes: ele?.DM ? ele.DM : 0,
            Hypertension: ele?.HTN ? ele.HTN : 0,
            Cancer: ele?.Cancer ? ele.Cancer : 0,
            Cardiovascular_Disease: ele?.CAD ? ele.CAD : 0,
        },
        Prediction: {
            RP_pilot_Score_1y: ele?.RP_pilot_Score_1y ? ele.RP_pilot_Score_1y : ele?.RP_pilot_Score_1y === 0 ? "1" : "-",
            RP_pilot_Score_2y: ele?.RP_pilot_Score_2y ? ele.RP_pilot_Score_2y : ele?.RP_pilot_Score_2y === 0 ? "1" : "-",
            RP_pilot_Score_5y: ele.RP_pilot_Score_5y ? ele.RP_pilot_Score_5y : ele?.RP_pilot_Score_5y === 0 ? "1" : "-",
            RP_pilot_Category_1y: ele?.RP_pilot_Category_1y ? ele.RP_pilot_Category_1y : "-",
            RP_pilot_Category_2y: ele?.RP_pilot_Category_2y ? ele.RP_pilot_Category_2y : "-",
            RP_pilot_Category_5y: ele?.RP_pilot_Category_5y ? ele.RP_pilot_Category_5y : "-",
            IR_Score_1y: ele?.IR_Score_1y ? ele.IR_Score_1y : ele?.IR_Score_1y === 0 ? "1" : "-",
            IR_Score_2y: ele?.IR_Score_2y ? ele.IR_Score_2y : ele?.IR_Score_2y === 0 ? "1" : "-",
            IR_Score_5y: ele?.IR_Score_5y ? ele.IR_Score_5y : ele?.IR_Score_5y === 0 ? "1" : "-",
            IR_Category_1y: ele?.IR_Category_1y ? ele.IR_Category_1y : "-",
            IR_Category_2y: ele?.IR_Category_2y ? ele.IR_Category_2y : "-",
            IR_Category_5y: ele?.IR_Category_5y ? ele.IR_Category_5y : "-"
        },
        RPProgressBarData: getRPData(ele),
        PEProgressBarData: getPEData(ele)
    }
    
    //Clinical Guidance Recommendation 
    //when only IR values present
    if (!dataTemp.RPProgressBarData) {
        //IR High (for CKD5 patients) = RS’3 + RS3
        if (dataTemp?.PEProgressBarData?.severity?.toLowerCase() === 'high') {
            dataTemp.ClinicalGuidance = {
                FunctionDecline: FunctionDecline['high'],
                RRT: RRT[dataTemp?.PEProgressBarData?.severity?.toLowerCase()]
            }
        }
        /*
        IR Medium (for CKD5 patients) = RS’2 + RS2 
        IR Low (for CKD5 patients) = RS’2 + RS2
        */
        else {
            dataTemp.ClinicalGuidance = {
                FunctionDecline: FunctionDecline['medium'],
                RRT: RRT['medium']
            }
        }
    } else {
        if (dataTemp?.PEProgressBarData?.severity?.toLowerCase() === 'high') {
            if (dataTemp?.RPProgressBarData?.severity.toLowerCase() === 'high') {
                dataTemp.ClinicalGuidance = {
                    FunctionDecline: FunctionDecline['high'],
                    RRT: RRT['high']
                }
            } else {
                dataTemp.ClinicalGuidance = {
                    FunctionDecline: FunctionDecline['medium'],
                    RRT: RRT['high']
                }
            }
        } else if (dataTemp?.PEProgressBarData?.severity?.toLowerCase() === 'moderate') {
            if (dataTemp?.RPProgressBarData?.severity.toLowerCase() === 'low') {
                dataTemp.ClinicalGuidance = {
                    FunctionDecline: FunctionDecline['low_modified'],
                    RRT: RRT[dataTemp?.PEProgressBarData?.severity?.toLowerCase()]
                }
            } else {
                dataTemp.ClinicalGuidance = {
                    FunctionDecline: FunctionDecline[dataTemp?.RPProgressBarData?.severity?.toLowerCase()],
                    RRT: RRT[dataTemp?.PEProgressBarData?.severity?.toLowerCase()]
                }
            }
        } else {
            dataTemp.ClinicalGuidance = {
                FunctionDecline: FunctionDecline[dataTemp?.RPProgressBarData?.severity?.toLowerCase()],
                RRT: RRT[dataTemp?.PEProgressBarData?.severity?.toLowerCase()]
            }
        }
    }
    return dataTemp;
}
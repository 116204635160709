import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import MicrosoftLogin from "react-microsoft-login";
import { setAuth } from "../../redux/slices/auth.slices";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="secondary" href="https://awak.com/">
        AWAK
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export const Login = () => {
  const { msInstance } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleSubmit = (err, data, msal) => {
    dispatch(setAuth({accessToken: data.accessToken, msInstance: msal}))
  };

  const handleSkipLogin = () => {
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "secondary.light",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box sx={{ mt: 1, display: "flex" }}>
        <MicrosoftLogin
          buttonTheme="dark"
          withUserData={true}
          redirectUri={process.env.REACT_APP_REDIRECT_URL}
          postLogoutRedirectUri={process.env.REACT_APP_REDIRECT_URL}
          clientId="367953b6-3db0-4fc8-823a-a63db2892cce"
          authCallback={handleSubmit}
          useLocalStorageCache={false}
          tenantUrl={
            "https://login.microsoftonline.com/d66675d6-bd41-4583-8569-fc1157b24b34"
          }
        ></MicrosoftLogin>
      </Box>
      <Typography variant="body2" align="center">
        <br />
        Please use your official AWAK email id
      </Typography>
      <Copyright sx={{ mt: 5 }} />
    </Box>
  );
};
